import React, { useRef } from 'react'
import Layout from '../components/layout/Layout';
import FancyHeader from '../components/elements/FancyHeader';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import RichText from '../components/rich-text/RichText';
import LinkTo from '../components/elements/LinkTo';
import FancyLink from '../components/elements/FancyLink';
import SEO from '../components/SEO';

const Developer = ({ data }) => {
    const images = data?.allFile.nodes
    const page = data?.allContentfulPage.nodes[0]
    const { title, description: { text } } = page
    const { content } = page

    const Laptop = images.filter(image => image.name === "laptop-developer")[0]
    return (
        <Layout>
            <SEO
                title="Freelance Developer"
                description="Making things brings me joy. Being able to develop websites, services and software lets me do that."
            />
            <section className="flex relative bg-black" >
                <div className="flex md:flex-row flex-col max-w-screen-lg mx-auto py-12" style={{ minHeight: 530 }}>
                    <div className="md:w-1/2 px-4 relative z-10 order-2 md:order-1 self-center text-white">
                        <FancyHeader >{title}</FancyHeader>
                        <p>{text}</p>
                    </div>
                    <div className="w-2/3 md:w-1/2 self-center md:order-2 order-1">
                        <Img className="w-full mt-16 mx-auto" fluid={Laptop.childImageSharp.fluid}></Img>

                    </div>
                </div>
            </section>
            <section>
                <h2 className="font-heading text-center px-4 mt-6 md:mb-6">Here is some of my previous work</h2>
            </section>
            {content && content.map((content, index) => <Project key={content.title + index} reverse={index % 2 === 0} {...content} />)}
        </Layout>
    )
}


const Project = ({ title, reverse, description, logo, link, ...rest }) => {
    return <section
        data-aos="fade-up"
        data-aos-duration="2000"
        key={title} className="max-w-screen-lg mx-auto md:my-8 grid md:grid-cols-2 grid-rows-1 px-4">
        <div className={` ${reverse ? "order-1" : "order-2"} `}>
            <h2 className="font-heading">{title}</h2>
            <RichText text={description} className="space-y-4" />
            {link && <FancyLink linkTo={link.linkTo} className="font-normal">{link.title}</FancyLink>}
        </div>
        <div className={`w-full ${reverse ? "md:order-2" : "order-1"} flex my-24 md:my-12 px-4 `}  >
            <div className={`h-40 w-40 md:w-64 md:h-64 p-8 mx-auto  self-center transform rotate-45 flex ${!reverse && 'rounded-full'}`} style={{ boxShadow: 'inset 6px -6px 12px 0 rgba(0,0,0,0.30)' }}>
                {logo?.fluid && <Img fluid={logo.fluid} className="w-full self-center transform -rotate-45" />}
                {logo?.fluid === null && <img src={logo.file.url} alt={logo.title} className="w-full h-full self-center transform -rotate-45 mx-auto" />}
            </div>

        </div>
    </section>

}




export const DeveloperPageQuery = graphql`
    query DeveloperPageQuery {
        allFile(filter: {relativeDirectory: {eq: "pages/developer"}}) {
        nodes {
            name
            childImageSharp {
            fluid(maxWidth:1600, quality:95) {
                ...GatsbyImageSharpFluid
                }
            }
        }
        }
        allContentfulPage(filter: {title: {eq: "Developer"}}) {
            nodes {
                description {
                    text: description
                }
                title
                content {
                    logo: singleImage {
                        fluid(maxWidth: 300, quality:80) {
                                ...GatsbyContentfulFluid
                        }
                        file {
                            url
                        }
                    }
                    link {
                    linkTo
                    title
                    }
                    title
                    description {
                        raw
                    }   
                }
            }
        }
    }
`
export default Developer

